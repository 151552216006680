<template>
  <v-card style="max-width: 750px" elevation="0" class="mx-auto mt-4 pa-6 text-center">
    <h1 class="adsimple-221148541">Datenschutzerklärung</h1>
    <h2 class="adsimple-221148541">Einleitung und Überblick</h2>
    <p>Wir haben diese Datenschutzerklärung (Fassung 28.09.2021-221148541) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-221148541" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
      <strong class="adsimple-221148541">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
    <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-221148541">Begriffe leserfreundlich erklärt</strong>, <strong>Links</strong> zu weiterführenden Informationen geboten und <strong class="adsimple-221148541">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
      Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
    <h2 class="adsimple-221148541">Rechtsgrundlagen</h2>
    <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
      Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-221148541" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=221148541" target="_blank" rel="noopener noreferrer nofollow">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
    <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
    <ol>
      <li class="adsimple-221148541">
        <strong class="adsimple-221148541">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
      <li class="adsimple-221148541">
        <strong class="adsimple-221148541">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
      <li class="adsimple-221148541">
        <strong class="adsimple-221148541">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
      <li class="adsimple-221148541">
        <strong class="adsimple-221148541">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
    </ol>
    <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
    <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
    <ul class="adsimple-221148541">
      <li class="adsimple-221148541">In <strong class="adsimple-221148541">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-221148541">Datenschutzgesetz</strong>), kurz <strong class="adsimple-221148541">DSG</strong>.</li>
      <li class="adsimple-221148541">In <strong class="adsimple-221148541">Deutschland</strong> gilt das <strong class="adsimple-221148541">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-221148541"> BDSG</strong>.</li>
    </ul>
    <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
    <h2 class="adsimple-221148541">TLS-Verschlüsselung mit https</h2>
    <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
      Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.</p>
    <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
      Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
      Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.</p>
    <h2 class="adsimple-221148541">Kontaktdaten des Verantwortlichen</h2>
    <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
      Klitzekleinkunstkulturverein k4v<br />
      6833 Klaus<br />
      E-Mail: <a class="adsimple-221148541" href="mailto:office@musterfirma.com">info@kumscho.com</a>
      <br />
      Telefon: <span class="adsimple-221148541" style="font-weight: 400;">+43 664 3468765</span>
      <br />
    </p>
    <h2 class="adsimple-221148541">Rechte laut Datenschutzgrundverordnung</h2>
    <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
    <ul class="adsimple-221148541">
      <li class="adsimple-221148541">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
        <ul class="adsimple-221148541">
          <li class="adsimple-221148541">zu welchem Zweck wir die Verarbeitung durchführen;</li>
          <li class="adsimple-221148541">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
          <li class="adsimple-221148541">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
          <li class="adsimple-221148541">wie lange die Daten gespeichert werden;</li>
          <li class="adsimple-221148541">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
          <li class="adsimple-221148541">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
          <li class="adsimple-221148541">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
          <li class="adsimple-221148541">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
        </ul>
      </li>
      <li class="adsimple-221148541">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
      <li class="adsimple-221148541">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
      <li class="adsimple-221148541">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
      <li class="adsimple-221148541">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
      <li class="adsimple-221148541">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
        <ul class="adsimple-221148541">
          <li class="adsimple-221148541">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
          <li class="adsimple-221148541">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
          <li class="adsimple-221148541">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
        </ul>
      </li>
      <li class="adsimple-221148541">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
    </ul>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-221148541" href="https://www.dsb.gv.at/?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">https://www.dsb.gv.at/</a> finden und für Deutschland können Sie sich an die <a class="adsimple-221148541" href="https://www.bfdi.bund.de" target="_blank" rel="noopener noreferrer nofollow">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
    <p>
      <strong class="adsimple-221148541">Kurz gesagt:</strong> Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
    <h2 class="adsimple-221148541">Kommunikation</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Kommunikation Zusammenfassung</strong>
          <br />
          👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
          📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
          🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
          📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
    <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
    <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz vorschreibt.</p>
    <h3 class="adsimple-221148541">Betroffene Personen</h3>
    <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
    <h3 class="adsimple-221148541">Telefon</h3>
    <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
    <h3 class="adsimple-221148541">E-Mail</h3>
    <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
    <h3 class="adsimple-221148541">Online Formulare</h3>
    <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlagen</h3>
    <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
    <ul class="adsimple-221148541">
      <li class="adsimple-221148541">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
      <li class="adsimple-221148541">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
      <li class="adsimple-221148541">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
    </ul>
    <br/>
    <h2 class="adsimple-221148541">Sicherheit der Datenverarbeitung</h2>
    <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
    <p>Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
    <h2 class="adsimple-221148541">Newsletter Datenschutzerklärung</h2>
    <p>
      <span class="adsimple-221148541" style="font-weight: 400;">Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter und geben diese nicht weiter.</span>
    </p>
    <p>
      <span class="adsimple-221148541" style="font-weight: 400;">Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden.</span>
    </p>
    <h2 class="adsimple-221148541">PayPal Datenschutzerklärung</h2>
    <p>Wir nutzen auf unserer Website den Online-Bezahldienst PayPal. Dienstanbieter ist das amerikanische Unternehmen PayPal Inc. Für den europäischen Raum ist das Unternehmen PayPal Europe (S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg) verantwortlich. Mehr über die Daten, die durch die Verwendung von PayPal verarbeitet werden, erfahren Sie in der Privacy Policy auf <u>
      <a class="adsimple-221148541" href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"  rel="nofollow">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.</u>
    </p>
    <h2 class="adsimple-221148541">MailChimp Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">MailChimp Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Newsletter-Abonnenten<br />
          🤝 Zweck: Direktwerbung per E-Mail, Benachrichtigung über systemrelevante Ereignisse<br />
          📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die E-Mail-Adresse.<br />
          📅 Speicherdauer: Dauer des Bestehens des Abonnements<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist MailChimp?</h3>
    <p>Wie viele andere Webseiten verwenden auch wir auf unserer Website die Dienste des Newsletter-Unternehmens MailChimp. Der Betreiber von MailChimp ist das Unternehmen The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA. Dank MailChimp können wir Ihnen interessante Neuigkeiten sehr einfach per Newsletter zukommen lassen. Mit MailChimp müssen wir nichts installieren und können trotzdem aus einem Pool an wirklich nützlichen Funktionen schöpfen. Im Folgenden gehen wir näher auf dieses E-Mail-Marketing-Service ein und informieren Sie über die wichtigsten datenschutzrelevanten Aspekte.</p>
    <p>MailChimp ist ein cloudbasiertes Newsletter-Management-Service. „Cloudbasiert“ heißt, dass wir MailChimp nicht auf unserem eigenen Rechner bzw. Server installieren müssen. Wir nutzen den Dienst stattdessen über eine IT-Infrastruktur – die über das Internet verfügbar ist – auf einem externen Server. Diese Art eine Software zu nutzen, wird auch SaaS (Software as a Service) genannt. Die folgende Grafik zeigt schematisch, wie mailchimp E-Mails an Newsletter Empfänger verteilt.</p>
    <p>
      <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/05/mailchimp-overview.svg" alt="Systematische Funktion von mailchimp" width="100%" />
    </p>
    <p>Mit MailChimp können wir aus einer breiten Palette an verschiedenen E-Mail-Typen auswählen. Abhängig davon, was wir mit unserem Newsletter erreichen wollen, können wir Einzel-Kampagnen, regelmäßige Kampagnen, Autoresponder (automatische E-Mails), A/B Tests, RSS-Kampagnen (Aussendung in vordefinierter Zeit und Häufigkeit) und Follow-Up Kampagnen durchführen.</p>
    <h3 class="adsimple-221148541">Warum verwenden wir MailChimp auf unserer Website?</h3>
    <p>Grundsätzlich nutzen wir einen Newsletter-Dienst, damit wir mit Ihnen in Kontakt bleiben. Wir wollen Ihnen erzählen was es bei uns Neues gibt oder welche attraktiven Angebote wir gerade in unserem Programm haben. Für unsere Marketing-Maßnahmen suchen wir immer die einfachsten und besten Lösungen. Und aus diesem Grund haben wir uns auch für das Newsletter-Management-Service von Mailchimp entschieden. Obwohl die Software sehr einfach zu bedienen ist, bietet sie eine große Anzahl an hilfreichen Features. So können wir in nur kurzer Zeit interessante und schöne Newsletter gestalten. Durch die angebotenen Designvorlagen gestalten wir jeden Newsletter ganz individuell und dank des „Responsive Design“ werden unsere Inhalte auch auf Ihrem Smartphone (oder einem anderen mobilen Endgeräten) leserlich und schön angezeigt.</p>
    <p>Durch Tools wie beispielsweise den A/B-Test oder den umfangreichen Analysemöglichkeiten, sehen wir sehr schnell, wie unsere Newsletter bei Ihnen ankommen. So können wir gegebenenfalls reagieren und unser Angebot oder unsere Dienstleistungen verbessern.</p>
    <p>Ein weiterer Vorteil ist das „Cloudsystem“ von Mailchimp. Die Daten werden nicht direkt auf unserem Server abgelegt und verarbeitet. Wir können die Daten von externen Servern abrufen und schonen auf diese Weise unseren Speicherplatz. Zudem wird der Pflegeaufwand deutlich geringer.</p>
    <h3 class="adsimple-221148541">Welche Daten werden von MailChimp gespeichert?</h3>
    <p>Die Rocket Science Group LLC (MailChimp) unterhält Online-Plattformen, die es uns ermöglichen, mit Ihnen (sofern Sie unseren Newsletter abonniert haben) in Kontakt zu treten. Wenn Sie über unsere Website Abonnent unseres Newsletters werden, bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste von MailChimp. Damit MailChimp auch nachweisen kann, dass Sie sich in den „Listenprovider“ eingetragen haben, werden das Datum der Eintragung und Ihre IP-Adresse gespeichert. Weiters speichert MailChimp Ihre E-Mail-Adresse, Ihren Namen, die physische Adresse und demografische Informationen, wie Sprache oder Standort.</p>
    <p>Diese Informationen werden verwendet, um Ihnen E-Mails zu senden und bestimmte andere MailChimp-Funktionen (wie z.B. Auswertung der Newsletter) zu ermöglichen.</p>
    <p>MailChimp teilt Informationen auch mit Drittanbietern, um bessere Dienste bereitzustellen. Einige Daten teilt MailChimp auch mit Werbepartnern von Drittanbietern, um die Interessen und Anliegen seiner Kunden besser zu verstehen, damit relevantere Inhalte und zielgerichtete Werbung bereitgestellt werden können.</p>
    <p>Durch sogenannte „Web Beacons“ (das sind kleine Grafiken in HTML-E-Mails) kann MailChimp feststellen, ob die E-Mail angekommen ist, ob sie geöffnet wurde und ob Links angeklickt wurden. All diese Informationen werden auf den MailChimp-Servern gespeichert. Dadurch erhalten wir statistische Auswertungen und sehen genau, wie gut unser Newsletter bei Ihnen ankam. Auf diese Weise können wir unser Angebot viel besser an Ihre Wünsche anpassen und unser Service verbessern.</p>
    <p>MailChimp darf zudem diese Daten auch zur Verbesserung des eigenen Service-Dienstes verwenden. Dadurch kann beispielsweise der Versand technisch optimiert werden oder der Standort (das Land) der Empfänger bestimmt werden.</p>
    <p>Die folgenden Cookies können von Mailchimp gesetzt werden. Dabei handelt es sich nicht um eine vollständige Cookie-Liste, sondern vielmehr um eine exemplarische Auswahl:</p>
    <p>
      <strong class="adsimple-221148541">Name</strong>: AVESTA_ENVIRONMENT<br />
      <strong class="adsimple-221148541">Wert: </strong>Prod<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie ist notwendig, um die Mailchimp-Dienste zur Verfügung zu stellen. Es wird immer gesetzt, wenn ein User sich für eine Newsletter-Mailing-Liste registriert.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach Sitzungsende</p>
    <p>
      <strong class="adsimple-221148541">Name</strong>: ak_bmsc<br />
      <strong class="adsimple-221148541">Wert: </strong>F1766FA98C9BB9DE4A39F70A9E5EEAB55F6517348A7000001221148541-3<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie wird verwendet, um einen Menschen von einem Bot unterscheiden zu können. So können sichere Berichte über die Nutzung einer Website erstellt werden.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Stunden</p>
    <p>
      <strong class="adsimple-221148541">Name</strong>: bm_sv<br />
      <strong class="adsimple-221148541">Wert: </strong>A5A322305B4401C2451FC22FFF547486~FEsKGvX8eovCwTeFTzb8//I3ak2Au…<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie ist von MasterPass Digital Wallet (ein MasterCard-Dienst) und wird verwendet, um einem Besucher einen virtuellen Zahlungsvorgang sicher und einfach anbieten zu können. Dafür wird der User auf der Website anonym identifiziert.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Stunden</p>
    <p>
      <strong class="adsimple-221148541">Name</strong>: _abck<br />
      <strong class="adsimple-221148541">Wert: </strong>8D545C8CCA4C3A50579014C449B045221148541-9<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Wir konnten über den Zweck dieses Cookies keine näheren Informationen in Erfahrung bringen<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach einem Jahr</p>
    <p>Manchmal kann es vorkommen, dass Sie unseren Newsletter zur besseren Darstellung über einen angegebenen Link öffnen. Das ist zum Beispiel der Fall, wenn Ihr E-Mail-Programm nicht funktioniert oder der Newsletter nicht ordnungsgemäß anzeigt wird. Der Newsletter wir dann über eine Website von MailChimp angezeigt. MailChimp verwendet auf seinen eigenen Webseiten auch Cookies (kleine Text-Dateien, die Daten auf Ihrem Browser speichern). Dabei können personenbezogenen Daten durch MailChimp und dessen Partner (z.B. Google Analytics) verarbeitet werden. Diese Datenerhebung liegt in der Verantwortung von MailChimp und wir haben darauf keinen Einfluss. Im „Cookie Statement“ von MailChimp (unter: <a class="adsimple-221148541" href="https://mailchimp.com/legal/cookies/" target="_blank" rel="noopener noreferrer nofollow" >https://mailchimp.com/legal/cookies/</a>) erfahren Sie genau, wie und warum das Unternehmen Cookies verwendet.</p>
    <h3 class="adsimple-221148541">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Da MailChimp ein amerikanisches Unternehmen ist, werden alle gesammelten Daten auch auf amerikanischen Servern gespeichert.</p>
    <p>Grundsätzlich bleiben die Daten auf den Servern von Mailchimp dauerhaft gespeichert und werden erst gelöscht, wenn eine Aufforderung von Ihnen erfolgt. Sie können Ihren Kontakt bei uns löschen lassen. Das entfernt für uns dauerhaft all Ihre persönlichen Daten und anonymisiert Sie in den Mailchimp-Berichten. Sie können allerdings auch direkt bei MailChimp die Löschung Ihrer Daten anfordern. Dann werden dort all Ihre Daten entfernt und wir bekommen eine Benachrichtigung von MailChimp. Nachdem wir die E-Mail erhalten haben, haben wir 30 Tage Zeit, um Ihren Kontakt von allen verbundenen Integrationen zu löschen.</p>
    <h3 class="adsimple-221148541">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie können Ihre Zustimmung für den Erhalt unseres Newsletters jederzeit innerhalb der empfangenen E-Mail per Klick auf den Link im unteren Bereich entziehen. Wenn Sie sich mit einem Klick auf den Abmeldelink abgemeldet haben, werden Ihre Daten bei MailChimp gelöscht.</p>
    <p>Falls Sie über einen Link in unserem Newsletter auf eine Website von MailChimp gelangen und Cookies in Ihrem Browser gesetzt werden, können Sie diese Cookies jederzeit löschen oder deaktivieren.</p>
    <p>Je nach Browser funktioniert das Deaktivieren bzw. Löschen etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p>
      <a class="adsimple-221148541" href="https://support.google.com/chrome/answer/95647?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
    <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Das Versenden unseres Newsletters durch Mailchimp erfolgt auf Grundlage Ihrer <strong class="adsimple-221148541">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet haben. Wenn eine Einwilligung nicht nötig ist, dann erfolgt der Newsletter-Versand auf Grundlage des <strong class="adsimple-221148541">berechtigten Interesses</strong> am Direktmarketing (Artikel 6 Abs. 1 lit. f), sofern dies rechtlich erlaubt ist. Ihren Registrierungsprozess zeichnen wir auf, damit wir stets nachweisen können, dass dieser unseren Gesetzen entspricht.</p>
    <p>Mehr über den Einsatz von Cookies bei MailChimp erfahren Sie auf <a class="adsimple-221148541" href="https://mailchimp.com/legal/cookies/" target="_blank" rel="noopener noreferrer nofollow">https://mailchimp.com/legal/cookies/</a>, Informationen zum Datenschutz bei MailChimp (Privacy) können Sie auf <a class="adsimple-221148541" href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer nofollow">https://mailchimp.com/legal/privacy/</a> nachlesen.</p>
    <h2 class="adsimple-221148541">Google Analytics Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Google Analytics Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
          📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
          📅 Speicherdauer: abhängig von den verwendeten Properties<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist Google Analytics?</h3>
    <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</p>
    <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
    <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
    <ul class="adsimple-221148541">
      <li class="adsimple-221148541">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
      <li class="adsimple-221148541">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
      <li class="adsimple-221148541">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
      <li class="adsimple-221148541">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
      <li class="adsimple-221148541">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
      <li class="adsimple-221148541">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
    </ul>
    <h3 class="adsimple-221148541">Warum verwenden wir Google Analytics auf unserer Website?</h3>
    <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
    <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
    <h3 class="adsimple-221148541">Welche Daten werden von Google Analytics gespeichert?</h3>
    <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
    <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
    <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
    <p>Folgende Cookies werden von Google Analytics verwendet:</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> _ga<br />
      <strong class="adsimple-221148541">Wert: </strong>2.1326744211.152221148541-5<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> _gid<br />
      <strong class="adsimple-221148541">Wert: </strong>2.1687193234.152221148541-1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 24 Stunden</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> _gat_gtag_UA_<br />
      <strong class="adsimple-221148541">Wert:</strong> 1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_.<br />
      <strong class="adsimple-221148541">Ablaufdatum: </strong>nach 1 Minute</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> AMP_TOKEN<br />
      <strong class="adsimple-221148541">Wert:</strong> keine Angaben<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utma<br />
      <strong class="adsimple-221148541">Wert: </strong>1564498958.1564498958.1564498958.1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utmt<br />
      <strong class="adsimple-221148541">Wert:</strong> 1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_ zum Drosseln der Anforderungsrate verwendet.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 10 Minuten</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utmb<br />
      <strong class="adsimple-221148541">Wert: </strong>3.10.1564498958<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 30 Minuten</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utmc<br />
      <strong class="adsimple-221148541">Wert:</strong> 167421564<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> Nach Schließung des Browsers</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utmz<br />
      <strong class="adsimple-221148541">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 6 Monaten</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __utmv<br />
      <strong class="adsimple-221148541">Wert:</strong> keine Angabe<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
    <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
    <p>
      <strong class="adsimple-221148541">Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
    <p>
      <strong class="adsimple-221148541">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
    <p>
      <strong class="adsimple-221148541">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
    <p>
      <strong class="adsimple-221148541">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
    <p>
      <strong class="adsimple-221148541">IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</p>
    <p>
      <strong class="adsimple-221148541">Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
    <p>
      <strong class="adsimple-221148541">Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
    <p>
      <strong class="adsimple-221148541">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
    <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
    <h3 class="adsimple-221148541">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a class="adsimple-221148541" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer nofollow">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
    </p>
    <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
    <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</p>
    <p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
    <ul class="adsimple-221148541">
      <li class="adsimple-221148541">Löschung nach 14 Monaten</li>
      <li class="adsimple-221148541">Löschung nach 26 Monaten</li>
      <li class="adsimple-221148541">Löschung nach 38 Monaten</li>
      <li class="adsimple-221148541">Löschung nach 50 Monaten</li>
      <li class="adsimple-221148541">Keine automatische Löschung</li>
    </ul>
    <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
    <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
    <h3 class="adsimple-221148541">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a class="adsimple-221148541" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer nofollow" >https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
    <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:</p>
    <p>
      <a class="adsimple-221148541" href="https://support.google.com/chrome/answer/95647?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow">Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong class="adsimple-221148541"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
    <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong class="adsimple-221148541">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.</p>
    <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Google. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus anderen Diensten von Google, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
    <p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a class="adsimple-221148541" href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer nofollow">http://www.google.com/analytics/terms/de.html</a> und <a class="adsimple-221148541" href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener noreferrer nofollow">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
    <h2 class="adsimple-221148541">YouTube Datenschutzerklärung</h2>
    <table style="border: 1px" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">YouTube Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Optimierung unserer Serviceleistung<br />
          📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
          Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
          📅 Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den Dienstzweck nötig sind<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
export default {
  name: "DataPrivacy"
}
</script>

<style scoped>

</style>